<template>
  <v-card
    class="elevation-20"
    style="max-width: 400px"
    :color="asuulga.bgColor ? asuulga.bgColor : 'orange'"
    dark
    @click="_goDetail"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col cols="8">
          <v-card-title>
            <div>
              <div class="headline">
                {{ asuulga.name }}
              </div>
              <div>{{ asuulga.description }}</div>
              <div>(2021)</div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="4">
          <v-img
            contain
            width="125px"
            src="https://cdn.vuetifyjs.com/images/cards/halcyon.png"
          />
        </v-col>
      </v-row>
      <br>
      <v-divider dark />
    </v-container>
    <v-card-actions class="pa-4">
      <v-row
        align="start"
        justify="start"
      >
        <v-icon
          class="ml-2 mr-1"
          size="18"
        >
          mdi-heart
        </v-icon>
        <span class="subheading mr-2">{{ asuulga.likes }}</span>
        <span class="mr-1">·</span>
        <v-icon
          class="mr-1"
          size="18"
        >
          mdi-share-variant
        </v-icon>
        <span class="subheading">{{ asuulga.shared }}</span>
      </v-row>
      <v-spacer />
      <span class="grey--text text--lighten-2 caption mr-2">
        {{ asuulga.rating }}
      </span>
      <v-rating
        v-model="rating"
        background-color="white"
        color="yellow accent-4"
        dense
        half-increments
        hover
        size="18"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      asuulga: null,
    },
    data: () => ({
      item: {
        color: '#952175',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'Halcyon Days',
        artist: 'Ellie Goulding',
      },
      rating: 3,
    }),
    methods: {
      _goDetail() {
        console.log(this.asuulga.id)
        this.$router.push({
          name: 'YQuesionaryCardDetail',
          params: { courseId: this.asuulga.id },
        })
      },
    },
  }
</script>
